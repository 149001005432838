
import React, { useState, useEffect } from "react";
import { Typography, Button, TextareaAutosize, Link, TextField, Grid, Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import paeprClipIcon from "../../assets/images/paper-clip.svg";
import DocumentIcon from "../../assets/images/document.svg";
import deleteIcon from "../../assets/images/delete-new-icon.svg";
import { success, danger } from "../../Components/shared/messageBox";
import { handleSpaces } from "../../helpers/helper-data";
import ApiCalls from "../../api/customAxios";
import { browserName, browserVersion } from "react-device-detect";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import copy from "copy-to-clipboard";
import { useRef } from "react";

function FeedBackModal(props) {
    const { open, onClose } = props;
    const formref = useRef(null);
    const currentUrl = window.location.href;
    const appVersions = process.env.REACT_APP_APP_VERSION;
    const candidateInfo = useSelector((state) => state.profile.candidate);
    const [review, setReview] = useState("");
    const hiddenFileInput = React.useRef(null);
    const [ipAddress, setIpAddress] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [browserWidth, setBrowserWidth] = useState(null);
    const [browserHeight, setBrowserHeight] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const browser = browserName;
    const version = browserVersion;
    const operatingSystem = getOperatingSystem();
    const [subjectError, setSubjectError] = useState("");
    const [subject, setSubject] = useState("");
    function getOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/win64|wow64/i.test(userAgent)) {
            return "Windows 64-bit";
        }

        if (/win32/i.test(userAgent)) {
            return "Windows 32-bit";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iphone|ipad|ipod/i.test(userAgent)) {
            return "iOS";
        }

        if (/mac/i.test(userAgent)) {
            return "Mac OS";
        }

        if (/linux/i.test(userAgent)) {
            return "Linux";
        }

        return null;
    };
    useEffect(() => {
        if (open) {
            const fetchLocation = () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLatitude(position.coords.latitude);
                            setLongitude(position.coords.longitude);
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                } else {
                    console.error('Geolocation is not supported by this browser.');
                }
            };
            fetchLocation();
        }
    }, [open]);
    useEffect(() => {
        if (open) {
            const fetchIpAddress = async () => {
                try {
                    const response = await fetch('https://api.ipify.org/?format=json');
                    const data = await response.json();
                    setIpAddress(data.ip);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchIpAddress();
        }
    }, [open]);
    useEffect(() => {
        const handleResize = () => {
            setBrowserWidth(window.innerWidth);
            setBrowserHeight(window.innerHeight);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [open]);
    const [isScreenshotTrue, setIsScreenshotTrue] = useState([]);
    const takefile = () => {
        hiddenFileInput.current.value = null;
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded && fileUploaded.type.startsWith('image/')) {
            if (fileUploaded.size <= 1000000) {
                setIsScreenshotTrue([...isScreenshotTrue, fileUploaded]);
            } else {
                danger("Maximum upload size exceeded. Try to upload a file upto 1MB.");
            }
        } else {
            danger("Please upload only JPG, JPEG, PNG formats!");
        }
        event.target.value = null;
    };
    // html2json
    const TextElem = (e) => ({
        type: 'TextElem',
        textContent: e.textContent
    });
    const fromNode = (e) => {
        switch (e?.nodeType) {
          case 1: return Elem(e);
          case 3: return TextElem(e);
          default: throw Error(`unsupported nodeType: ${e.nodeType}`);
        }
    };
    const Elem = (e) => ({
        type: 'Elem',
        tagName: e.tagName,
        // attributes: Array.from(e.attributes, ({ name, value }) => [name, value]),
        children: Array.from(e.childNodes, fromNode)
    });
    const html2json = (e) => JSON.stringify(Elem(e), null, '  ');

    const getIds = (url) => {
        let id = "";
          if (window.location.pathname == '/' || url.includes('/home')) {
            id = document.getElementById('home-page');
          } else if (url.includes('/candidate/find-jobs')) {
            id = document.getElementById('find-jobs');
          } else if (url.includes('/candidate/job-details')) {
            id = document.getElementById('job-details');
          } else if (url.includes('/candidate/my-jobs')) {
            id = document.getElementById('my-jobs-list');
          } else if (url.includes('/candidate/analytics')) {
            id = document.getElementById('analytics');
          } else if (url.includes('/candidate/add-checklist')) {
            id = document.getElementById('check-list');
          } else if (url.includes('/candidate/interviews')) {
            id = document.getElementById('interview-list');
          } else if (url.includes('/candidate/interviewDetails')) {
            id = document.getElementById('interview-details');
          } else if (url.includes('/candidate/message-list')) {
            id = document.getElementById('message-list');
          } else if (url.includes('/candidate/edit-profile')) {
            id = document.getElementById('profile-screen');
          } else if (url.includes('/candidate/public')) {
            id = document.getElementById('public-profile');
          } else if (url.includes('/candidate/settings')) {
            id = document.getElementById('settings-screen');
          } else if (url.includes("/candidate/dashboard")) {
            id = document.getElementById("dashboard");
          }
        return id;
      };
      const getMenuName = (url) => {
        let id;
          if (window.location.pathname == '/' || url.includes('/home')) {
            id = "Home";
          } else if (url.includes('/candidate/find-jobs')) {
            id = "Find Jobs";
          } else if (url.includes('/candidate/job-details')) {
            id = "Job Details";
          } else if (url.includes('/candidate/my-jobs')) {
            id = "My Jobs";
          } else if (url.includes('/candidate/analytics')) {
            id = "Analytics";
          } else if (url.includes('/candidate/add-checklist')) {
            id = "Add Checklist";
          } else if (url.includes('/candidate/interviews')) {
            id = "Interviews";
          } else if (url.includes('/candidate/interviewDetails')) {
            id = "Interview Details";
          } else if (url.includes('/candidate/message-list')) {
            id = "Message List";
          } else if (url.includes('/candidate/edit-profile')) {
            id = 'Edit Profile';
          } else if (url.includes('/candidate/public')) {
            id = "Public Profile";
          } else if (url.includes('/candidate/settings')) {
            id = "Settings";
          } else if (url.includes("/candidate/dashboard")) {
            id = "Dashboard";
          }
          return id;
      };
    const handleSubmit = () => {
        setIsLoading(true);
        const formData = new FormData();
        ApiCalls.get("feedback/exists", formData).then((response) => {
            if (response) {
                const formData = new FormData();
                isScreenshotTrue.forEach((image, index) => {
                    formData.append("attachments", image);
                });
                formData.append('request-body', new Blob([JSON.stringify({
                    browserName: browser,
                    browserVersion: version,
                    appVersion: appVersions,
                    resolution: `${browserWidth}x${browserHeight}`,
                    platform: operatingSystem,
                    subject: subject,
                    application: "DIRECT SOURCING CANDIDATE",
                    menuUrl: getMenuName(window.location.pathname),
                    feedback: review,
                    ipAddress: ipAddress,
                    userId: candidateInfo.id,
                    latitude: latitude,
                    longitude: longitude,
                    formData: html2json(getIds(window.location.pathname))
                })], {
                    type: "application/json"
                }));
                ApiCalls.post("feedback", formData).then((response) => {
                    if (response) {
                        success("Thanks for the feedback.");
                        setSubject("");
                        setReview("");
                        setIsScreenshotTrue([]);
                        setIsLoading(false);
                        onClose();
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    if (error?.statusCode != 500) {
                        danger("Something went Wrong");
                    }
                });
            } else {
                const payload = {
                    Feedback: review,
                    Application: "DIRECT SOURCING CANDIDATE",
                    AppVersion: appVersions,
                    Platform: operatingSystem,
                    MenuName: getMenuName(window.location.pathname),
                    Subject: subject,
                    UserId: candidateInfo.id,
                    FirstName: candidateInfo?.firstName,
                    LastName: candidateInfo.lastName,
                    Email: candidateInfo.emailAddress,
                    Latitude: latitude,
                    Longitude: longitude,
                    IpAddress: ipAddress,
                    Resolution: `${browserWidth}x${browserHeight}`,
                    BrowserName: browser,
                    BrowserVersion: version,
                    formData: html2json(getIds(window.location.pathname))
                };
                const payloadString = Object.entries(payload)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(", ");
                copy(JSON.stringify(payloadString));
                success("Details are copied to the clipboard.");
                setSubject("");
                setReview("");
                setIsScreenshotTrue([]);
                setIsLoading(false);
                onClose();
            }
        }).catch((err) => {
            setIsLoading(false);
        });
    };
    const handleDeleteImage = (id) => {
        if (isScreenshotTrue.length > 1) {
            setIsScreenshotTrue(
                isScreenshotTrue.filter((location, _index) => _index !== id)
            );
        } else {
            setIsScreenshotTrue([]);
        }
    };
    const handleReset = () => {
        setReview("");
        setIsScreenshotTrue([]);
    };
    const handleModalClose = () => {
        setSubject("");
        setReview("");
        setIsScreenshotTrue([]);
        onClose();
    };
    if (isLoading) {
        return <Loader />;
    }
    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-content align-left inputcontrasts feedback-width galaxy-width">
                <Grid container id="modal-title">
                    <Grid item xs={11}>
                        <Typography
                            className="f-16 font-weight-600 heading-text-color modalTitle black-heading-color p-l-5"
                            align="left"
                        >
                            Feedback
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "-10px" }}>
                        <Typography align="right" className="sub-text-color" onClick={handleModalClose}>
                            <CloseIcon className="cursor-hover" />
                        </Typography>
                    </Grid>
                </Grid>

                <Grid id="modal-description" className="feebackModal-height">
                    <Grid item xs={12}>
                    <Typography className="font-weight-400 f-14 mb-10 gray8">
                            Subject
                            <span className="">*</span>
                        </Typography>
                        <Typography className="input-focus-none mb-10">
                        <TextField
                  type="text"
                  className="form-control input-group m-b-0 inputcontrasts"
                  margin="normal"
                  placeholder="Enter subject"
                  value={subject}
                  onChange={(e) => {
                                    if (e.target.value.length === 1) {
                                        setSubject(e.target.value.toUpperCase());
                                        return;
                                    }
                                    if (e.target.value.length > 128) {
                                        return;
                                        setSubjectError("Exceeded maximum character length of 128");
                                    } else {
                                        setSubjectError("");
                                    }
                                    setSubject(e.target.value);
                                }}
                                onKeyPress={handleSpaces}
                />
                        </Typography>
                        <Typography className="font-weight-400 f-14 mb-10 gray8">
                            Tell us about your experience
                            <span className="">*</span>
                        </Typography>
                        <Typography className="input-focus-none">
                            <TextareaAutosize
                                minRows={2}
                                maxRows={4}
                                aria-label="maximum height"
                                className="form-control input-group inputcontrasts"
                                maxLength={256}
                                inputProps={{ maxLength: 256 }}
                                onChange={(e) => {
                                    if (e.target.value.length === 1) {
                                        setReview(e.target.value.toUpperCase());
                                        return;
                                    }
                                    setReview(e.target.value);
                                }}
                                onKeyPress={handleSpaces}
                                name="review"
                                id="review"
                                value={review}
                                placeholder="Enter feedback"
                                style={{ height: "150px" }}
                            />
                            <Typography
                                align="right"
                                className="sub-text-color sub-text-font"
                            >
                                {review?.length ? review?.length : 0}/256
                            </Typography>
                        </Typography>
                        <Typography>
                            {isScreenshotTrue?.length > 0 && <Typography className="display-flex align-center mb-10" style={{ flexDirection: "column" }}>
                                <>
                                    {isScreenshotTrue?.map((image, key) => {
                                        return <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            key={key}
                                            className="display-flex m-t-5 justify-between file-cards p-0 m-l-5"
                                            style={{ width: "100%" }}
                                        >
                                            <Grid
                                                item
                                                lg={10}
                                                md={10}
                                                xs={10}
                                                className="display-flex align-center"
                                            >
                                                {" "}
                                                <img
                                                    src={DocumentIcon}
                                                    alt="img"
                                                    className="mr-10 ml-10"
                                                    width="18px"
                                                    height="18px"
                                                />
                                                {" "}
                                                <p className="gray7 f-14 textEllipse text-capitalize">
                                                    {image?.name}
                                                </p>{" "}
                                            </Grid>
                                            <Grid
                                                item
                                                lg={2}
                                                md={2}
                                                xs={2}
                                                className="font-13 align-item-right display-flex"
                                            >
                                                <span
                                                    tabIndex={0}
                                                    onKeyPress={(e) => handleDeleteImage(key)}
                                                    className="align-center display-flex sub-text-font mr-10 cursor-hover gray7"
                                                    onClick={(e) => handleDeleteImage(key)}
                                                >
                                                    <img
                                                        className="sub-text-font"
                                                        src={deleteIcon}
                                                        alt="delete icon"
                                                    />
                                                </span>
                                            </Grid>
                                        </Grid>;
                                    })}
                                </>
                            </Typography>}
                            {isScreenshotTrue?.length <= 1 &&
                                <Typography className="display-flex align-center">
                                    <img src={paeprClipIcon} />
                                    <Typography className="cursor-hover f-14 font-weight-400 gray8 m-l-5" onClick={takefile}>Add Screenshot</Typography>
                                    <input type="file" accept="image/jpeg, image/png, image/jpg, image/gif" style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleChange} />
                                </Typography>}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid id="modal-footer" className="justify-end display-flex">
                    <Typography className="display-flex align-center">
                        {/* <Typography className="link-color f-14 font-weight-400 mr-20" onClick={handleReset}>Reset</Typography> */}
                        <Button
                            className="btn primary-button"
                            disabled={subject === "" || review === "" || subjectError}
                            onClick={handleSubmit}
                            tabIndex={0}
                            disableRipple="true"
                        >
                            Submit
                        </Button>
                    </Typography>
                </Grid>
            </Box>
        </Modal>
    );
}

export default FeedBackModal;
