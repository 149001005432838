import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Components/LaunchPage/Footer";
// import Navbar from "../Components/LaunchPage/Navbar";
import NavbarNew from "../Components/LaunchPage/NewNavbar";
import NewFooter from "../Components/NewLaunchPage/NewFooter";
import HireTopTalent from "../Components/NewLaunchPage/HireTopTalent";
import QualityCandidates from "../Components/NewLaunchPage/QualityCandidates";
import LaunchPage from "../Components/LaunchPageNew";

import CandidateFeature from "../Components/CandidateFeature";
import { includes } from "lodash";
import { HideScroll } from "react-hide-on-scroll";
import { Grid } from "@mui/material";
import Loader from "../pages/Loader";
import { useSelector } from "react-redux";
import HealthCareNavbar from "../Components/LaunchPage/HealthCareNavbar";
import KeycloakService from "../keycloak";
import Constants from "../helpers/constants";

const DefaultLayout = (props) => {
  const appOrigin = useSelector((state) => state.location.origin);
  const location = useLocation();
  const URLPath = location.pathname;
  const isAuthenticated = KeycloakService.isLoggedIn();
  const { isDisplay } = props;
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
      <Loader />;
    }, 1200);
  }, []);

  return (
    <>
      <Helmet>
        {appOrigin === "D" ?
          <title>Your one stop shop to fulfill your career needs | {Constants.APP_NAME}</title>
          : appOrigin === "N" ? <title>Your one stop shop to fulfill your career needs | {Constants.NEURODIVERSIFY}</title> :
          <title>Your one stop shop to fulfill your career needs | {Constants.DIRECT_HEALTH}</title>
        }
        {/* <title>
          Your one stop shop to fulfill your career needs | SimplifyHire
        </title> */}
        <meta
          name="description"
          content={`Your one stop shop to fulfill your career needs | ${Constants.APP_NAME}`}
        />
        <link
          rel="MetaBlue"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_OUT}
        />
      </Helmet>

      {/* <NewNavBar /> */}
      {URLPath.split("/")[2] === "public" || URLPath == "/job-list" ? (
        appOrigin === "D" ||
        URLPath.split("/")[2] === "public" ||
        appOrigin === "N" ? (
          <NavbarNew />
        ) : (
          <HealthCareNavbar />
        )
      ) : (
        <>
          {/* <HideScroll variant="down"> */}
          <Grid className="sticky hideScrollDown">
            <NavbarNew />
            {/* {isAuthenticated ? <HeaderNew /> : <NavbarNew />} */}
          </Grid>
          {/* </HideScroll> */}
        </>
      )}

      {/* every child component pass from there */}
      <Grid item lg={12} md={12} xs={12} className="pt-20">
        <Outlet />
      </Grid>

      {URLPath === "feature" && <CandidateFeature />}

      {location.pathname.split("/")[2] !== "public" &&
        URLPath !== "/feature" &&
        URLPath !== "/job-list" &&
        !includes(URLPath, "/job") && (
          <>
            {/* <QualityCandidates />
            <HireTopTalent /> */}
            <LaunchPage />
          </>
        )}
      {/* {isDisplayed && !isDisplay && <NewFooter />} */}
      {isDisplayed && !isDisplay && <Footer />}
      {location.pathname == "/home" && <Footer />}
    </>
  );
};
export default DefaultLayout;
