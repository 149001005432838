import { Auth } from "aws-amplify";
import { logout } from "./helper";
import KeycloakService from "../keycloak";

export function authHeader() {
  // return authorization header with jwt token
  return new Promise((resolve, reject) => {
    resolve({
      Authorization: `Bearer ${KeycloakService.getToken()}`,
      "x-access-token": KeycloakService.getIdToken(),
    });
  });
}
