import axios from "axios";
import { includes } from "lodash";
import { authHeader } from "../helpers/auth-header";
import { logout } from "../helpers/helper";
import { danger } from "../Components/shared/messageBox";
import store from "../redux/store";
import { getPageNotFoundRecode } from "../redux/pageNotFoundReducer";

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
  timeout: 600000,
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    if (includes(config.url, "public")) {
      return config;
    }
    // Do something before request is sent
    const userToken = authHeader();
    await userToken
      .then((res) => {
        config.headers.common["Authorization"] = res.Authorization;
        config.headers.common["x-access-token"] = res["x-access-token"];
        if ((config.url.includes("candidates/notifications/C")) || (config.url.includes("candidates/notifications/unread/C"))) {
          config.headers.common["enterpriseId"] = null;
        }
        if (localStorage.getItem("synonyms")) {
          config.headers.common["x-synonyms-active"] = "T";
        }
      })
      .catch((e) => {
        // signOut();
      });

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // alert(JSON.stringify(error));
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      if (window.location.pathname.includes("candidate")) {
        signOut();
      }
    } else if (status === 404) {
      // exclude 404 for hide job details popup case
      if (error?.response?.data?.errorMessage != "as a candidate you are not authorized to view this job") {
        store.dispatch(getPageNotFoundRecode(true));
      }
    } else if (status === 405 && error?.response?.data?.errorMessage === "Interview Access denied.") {
      store.dispatch(getPageNotFoundRecode(true));
    }
    return Promise.reject(error);
  }
);

const signOut = async () => {
  await logout();
  window.location.href = "/signin";
};
export default class ApiCalls {
  static get(url) {
    return new Promise((resolve, reject) => {
      return api
        .get(url)
        .then((res) => resolve(res?.data?.responsePayload))
        // .catch((err) => reject(err?.response?.data?.errorMessage));
        .catch((err) => {
          if (err?.response && err?.response?.data?.statusCode === 500) {
            danger("Oops! Something went wrong. Please try again later.");
          }
            reject(err?.response?.data);
        });
    });
  }

  static post(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .post(url, payload)
        .then((res) => resolve(res?.data?.responsePayload))
        // .catch((err) => reject(err?.response?.data?.errorMessage));
        .catch((err) => {
          if (err?.response && err?.response?.data?.statusCode === 500) {
            danger("Oops! Something went wrong. Please try again later.");
          }
            reject(err?.response?.data);
        });
    });
  }

  static put(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .put(url, payload)
        .then((res) => resolve(res?.data?.responsePayload))
        // .catch((err) => reject(err?.response?.data?.errorMessage));
        .catch((err) => {
          if (err?.response && err?.response?.data?.statusCode === 500 && !url.includes("payrate/reports")) {
            danger("Oops! Something went wrong. Please try again later.");
          }
            reject(err?.response?.data);
        });
    });
  }

  static delete(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .delete(url, payload)
        .then((res) => resolve(res.data.responsePayload))
        // .catch((err) => reject(err?.response?.data?.errorMessage));
        .catch((err) => {
          if (err?.response && err?.response?.data?.statusCode === 500) {
            danger("Oops! Something went wrong. Please try again later.");
          }
            reject(err?.response?.data);
        });
    });
  }

  // to get the details out of the responsePayload
  static getfullResponse(url) {
    return new Promise((resolve, reject) => {
      return api
        .get(url)
        .then((res) => resolve(res?.data))
        // .catch((err) => reject(err?.response?.data));
        .catch((err) => {
          if (err?.response && err?.response?.data?.statusCode === 500) {
            danger("Oops! Something went wrong. Please try again later.");
          }
            reject(err?.response?.data);
        });
    });
  }
  static getDetailedResponse(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .put(url, payload)
        .then((res) => resolve(res?.data))
        // .catch((err) => reject(err?.response?.data));
        .catch((err) => {
          if (err?.response && err?.response?.data?.statusCode === 500) {
            danger("Oops! Something went wrong. Please try again later.");
          }
            reject(err?.response?.data);
        });
    });
  }

  // for reports only
  static getReport(url) {
    return new Promise((resolve, reject) => {
      return api
        .get(url)
        .then((res) => resolve(res))
        // .catch((err) => reject(err));
        .catch((err) => {
          if (err?.response && err?.response?.data?.statusCode === 500) {
            danger("Oops! Something went wrong. Please try again later.");
          }
            reject(err);
        });
    });
  }
}
