import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as SearchIcon } from "../../assets/images/search-icon-gray7.svg";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isSessionValid } from "../../helpers/helper";
import SearchFilter from "../../pages/Private/Jobs/SearchFilter";
import LocationSearchInput from "../LocationShortenSearchInput";
import { useSelector } from "react-redux";

export default function SearchBar(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  let navigate = useNavigate();
  const [showFiltersDropdown, setShowFiltersDropdown] = useState(false);
  const [keywordWhat, setKeywordWhat] = useState("");
  const location = useLocation();
  const search = useLocation().search;
  const [keywordSearch, setKeywordSearch] = useState(
    new URLSearchParams(search).get("keyword")
  );
  const [jobTitleFilterValue, setJobTitleFilterValue] = useState(
    keywordSearch ? keywordSearch : ""
  );
  const [closeIcon, setCloseIconState] = useState(false);
  const searchBar = new URLSearchParams(search).get("searchBar");
  const jobTitle = new URLSearchParams(search).get("jobTitle");
  const skillsStr = new URLSearchParams(search).get("skills");
  let name = new URLSearchParams(search).get("name");
  const keyword = new URLSearchParams(search).get("keyword");
  const city = new URLSearchParams(search).get("city");
  const state = new URLSearchParams(search).get("state");
  const country = new URLSearchParams(search).get("country");

  const [query, setQuery] = useState({
    skills: skillsStr ? skillsStr.split(",").map((x) => parseInt(x)) : "",
    city: city ? city : "",
    state: state ? state : "",
    country: country ? country : "",
    searchBar: searchBar ? searchBar : "",
    jobTitle: jobTitle ? jobTitle : "",
    name: name ? name : "",
    keyword: keyword ? keyword : "",
  });
  const [invalidLocation, setInvalidLocation] = useState(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    isSessionValid().then((res) => {
      setIsAuthenticated(res);
    });
  }, []);

  useEffect(() => {
    if (props.anchorEl5) {
      setJobTitleFilterValue(keyword ? keyword : "");
      if (keyword) {
        setCloseIconState(true);
      } else {
        setCloseIconState(false);
      }
      setQuery({
        skills: skillsStr ? skillsStr.split(",").map((x) => parseInt(x)) : "",
        city: city ? city : "",
        state: state ? state : "",
        country: country ? country : "",
        searchBar: searchBar ? searchBar : "",
        jobTitle: jobTitle ? jobTitle : "",
        name: name ? name : "",
        keyword: keyword ? keyword : "",
      });
    }
  }, [props.anchorEl5]);

  const handleJobTitleSearchFilter = (e) => {
    const value = e.target.value;
    setQuery({ ...query, keyword: value, name: "", jobTitle: "" });
    setJobTitleFilterValue(value);
    setKeywordSearch(value);
    setShowFiltersDropdown(true);
  };
  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      const value = e.target.value;
      setQuery({
        ...query,
        name: "",
        jobTitle: "",
        keyword: value,
      });
      submitFilters();
      setJobTitleFilterValue(value);
      setShowFiltersDropdown(false);
    }
  };
  const trimString = (str) => {
    if (str.length > 9) {
      return str.slice(0, 8);
    }
    return str;
  };
  const submitFilters = () => {
    setShowFiltersDropdown(false);
    let q = "";
    if (query.keyword) {
      q += `keyword=${encodeURIComponent(query.keyword.split(","))}&`;
      props.setSearchedSkills(trimString(query.keyword));
    }
    if (query.jobTitle) {
      q += `jobTitle=${query.jobTitle}&`;
    }
    if (query.name) {
      q += `name=${query.name}&`;
      props.setSearchedSkills(trimString(query.name));
    }
    if (query.skills) {
      q += `skills=${query.skills}&`;
    }
    if (query.city) {
      q += `city=${query?.city}&`;
      props.setSearchedLocation(trimString(query?.city));
    }
    if (query.state) {
      q += `state=${query?.state}&`;
    }
    if (query.country) {
      q += `country=${query?.country}&`;
    }
    props.handleClose5();
    props.setGlobalSearchClick(true);
    if (isAuthenticated) {
      navigate(`/candidate/find-jobs?${q}`);
    } else {
      navigate(`/job-list?${q}`);
    }
  };
  const clearFilters = () => {
    setJobTitleFilterValue("");
    setKeywordSearch("");
    setShowFiltersDropdown(false);
    props.setSearchedSkills("");
    props.setSearchedLocation("");
    props.setGlobalSearchClick(false);
    setQuery({
      ...query,
      city: "",
      state: "",
      country: "",
      keyword: "",
      jobTitle: "",
      name: "",
    });

    props.handleClose5();
    if (isAuthenticated) {
      navigate(`/candidate/find-jobs`);
    } else {
      navigate(`/job-list`);
    }
  };

  const selectText = (event) => {
    event.currentTarget?.select();
  };

  // Location
  const onSelectLocation = (data) => {
    if (!data.city) {
      setInvalidLocation(true);
      return;
    }
    setInvalidLocation(false);
    setQuery({
      ...query,
      city: data.city,
      state: data.state,
      country: data.country,
    });
  };
  const onKeyDown = (e) => {
    e.stopPropagation();
  };
  const receivedData = (data) => {
    setJobTitleFilterValue("");
    setKeywordSearch("");
    if (data.type == "S") {
      setQuery({
        ...query,
        skills: data?.id,
        name: data?.name,
        jobTitle: "",
        keyword: "",
      });
    } else {
      setQuery({
        ...query,
        name: data?.name,
        jobTitle: data?.name,
        keyword: "",
        skills: "",
      });
    }
    setShowFiltersDropdown(false);
  };
  const handleLocationClear = () => {
    props.setSearchedLocation("");
    setQuery({
      ...query,
      city: "",
      state: "",
      country: "",
    });
    let q = "";
    if (query.keyword) {
      q += `keyword=${encodeURIComponent(query.keyword.split(","))}&`;
    }
    if (query.jobTitle) {
      q += `jobTitle=${query.jobTitle}&`;
    }
    if (query.name) {
      q += `name=${query.name}&`;
      props.setSearchedSkills(trimString(query.name));
    }
    if (query.skills) {
      q += `skills=${query.skills}&`;
    }
    if (isAuthenticated) {
      if (window.location.href.includes("find-jobs")) {
        navigate(`/candidate/find-jobs?${q}`);
        props.handleClose5();
      }
    } else {
      if (window.location.href.includes("job-list")) {
        navigate(`/job-list?${q}`);
        props.handleClose5();
      }
    }
  };
  return (
    <Grid
      className={`my-search-collapse pr-35 pl-35 ${
        props.anchorEl5 ? "show" : ""
      }`}
      style={{
        borderTop: `${props.anchorEl5 ? "1px solid #E4E7EC" : "none"}`,
        // borderRadius: `${props.anchorEl5 ? "0px 0px 24px 24px" : {}}`,
      }}
    >
      <Grid className="pb-20 mt-15">
        <Grid className="display-flex w-100 pt-10">
          <div className="w-50" aria-label="Search bar">
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ zIndex: "11" }}
              item
              lg={12}
              aria-label="Search bar"
              className="global-search input-pair find-job-place"
            >
              <TextField
                className="border-right-0 form-control input-group autoInput px-10 w-100 search-border contrasts position-issue education-modal-align zooming-issue inputcontrasts"
                type="search"
                id="globalSearch"
                variant="outlined"
                margin="normal"
                placeholder={
                  appOrigin === "D" || appOrigin === "N"
                    ? "Search by skills, job title, job reference no, etc.,"
                    : "Search by specialization, job title, job reference no, etc.,"
                }
                onChange={handleJobTitleSearchFilter}
                onKeyUp={handleEnterKey}
                value={
                  jobTitleFilterValue
                    ? jobTitleFilterValue
                    : query.name
                    ? query.name
                    : query.keyword
                    ? query.keyword
                    : ""
                }
                inputProps={{ onDoubleClick: selectText, role: "" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        width="16px"
                        height="16px"
                        aria-label="Search icon"
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {(jobTitleFilterValue ||
                        query.name ||
                        query.keyword ||
                        keyword ||
                        closeIcon) && (
                        <>
                          <CloseIcon
                            onClick={() => {
                              setJobTitleFilterValue("");
                              setKeywordSearch("");
                              setQuery({
                                ...query,
                                skills: "",
                                jobTitle: "",
                                name: "",
                                keyword: "",
                              });
                              // window.location.href = "/candidate/job-list";
                              setCloseIconState(false);
                              let q = "";
                              if (query.city) {
                                q += `city=${query.city}&`;
                              }
                              if (query.state) {
                                q += `state=${query.state}&`;
                              }
                              if (query.country) {
                                q += `country=${query.country}&`;
                              }
                              if (isAuthenticated) {
                                if (
                                  window.location.href.includes("find-jobs")
                                ) {
                                  navigate(`/candidate/find-jobs?${q}`);
                                  props.handleClose5();
                                }
                              } else {
                                if (window.location.href.includes("job-list")) {
                                  navigate(`/job-list?${q}`);
                                  props.handleClose5();
                                }
                              }
                            }}
                            tabIndex={-1}
                            aria-label="Close icon"
                            className="cursor-hover"
                            // tabIndex="0"
                            onKeyPress={() => {
                              setJobTitleFilterValue("");
                              setKeywordSearch("");
                              // window.location.href = "/candidate/job-list";
                              props.handleClose5();
                            }}
                          />
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
                // aria-label="Search bar"
                // role="textbox"
              />
            </Grid>
            {showFiltersDropdown && jobTitleFilterValue && (
              <SearchFilter
                query={{ ...query, searchBar: jobTitleFilterValue }}
                setJobTitleFilterValue={setJobTitleFilterValue}
                isAuth={props.isAuth}
                setShowFiltersDropdown={setShowFiltersDropdown}
                setKeywordSearch={setKeywordSearch}
                getSelectedRecode={receivedData}
                setCloseIconState={setCloseIconState}
                enableScroll={true}
              />
            )}
          </div>
          <Typography
            className="w-50 location-input no-focus-search"
            // style={{ width: '50%' }}
          >
            <LocationSearchInput
              handleLocationClear={handleLocationClear}
              onKeyDown={onKeyDown}
              city={`${query?.city}${
                query?.city && (query?.state || query?.country) ? ", " : ""
              }${query?.state}${query?.state && query?.country ? ", " : ""}${
                query?.country
              }`}
              onSelectLocation={onSelectLocation}
              placeholder="Enter location"
              isAuthenticated={isAuthenticated}
            />
          </Typography>
        </Grid>
        <Grid className="mt-15 display-flex justify-end align-center">
          <Typography
            className={`f-14 font-weight-400 mr-20 cursor-hover ${window.location.pathname === "/" ? "home-page-link-color" : "link-color"}`}
            onClick={clearFilters}
          >
            Reset
          </Typography>
          <Button className={`btn ${window.location.pathname === "/" ? "home-page-button" : "primary-button"}`} onClick={submitFilters}>
            Search
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
