import { React } from 'react';
import { Box, Grid, Paper, Popper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ChatbotGreetingPopper = (props) => {
    return (
        <Popper
            open={props.openChatbotGreetingPopover}
            anchorEl={props.anchorEl2}
            placement="top-start"
            disablePortal
            className='chatbot-popper'
        >
            <Paper className='mb-10'>
                <Grid item>
                    <Box>
                        <Grid item xs={12}>
                            <Typography
                                id="modal-title"
                                className="display-flex justify-between pl-15 pr-15 pt-15 pb-10"
                                style={{ backgroundColor: "#F0F2F6", border: "1px solid #F0F2F6" }}
                            >
                                <Typography className="f-14 font-weight-400 gray11">
                                    Got any questions? I’m happy to help
                                </Typography>
                                <Typography
                                    align="right"
                                    className="cursor-hover label-large-text icon-button ml-10 m-t-2"
                                >
                                    <CloseIcon
                                        onClick={props.handleCloseChatbotGreetingPopover}
                                        onKeyPress={props.handleCloseChatbotGreetingPopover}
                                        aria-label="Close icon"
                                        tabIndex={0}
                                    />
                                </Typography>
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
            </Paper>
        </Popper>
    );
};
export default ChatbotGreetingPopper;
